.HeaderLivraison{
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(168deg, rgb(54,54,54) 60%,  white 60%);
    display: grid;
    border-radius: 0 0 0 0;
}

.truckContain{
    width: 100%;
    height: auto;
}

.img-fluidTruckContain{
    width: 150px;
    height: auto;
}

.moveTruck{
    opacity: 0;
    transform: translateX(0);
    animation: truckMoving 5s infinite ease-out ;
}

.headerlivraisonImgContain{
    width: 100%;
    height: auto;
    margin-left: 50px;
    margin-right: 0 auto;
}

.img-fluidheaderlivraisonImgContain{
    width: 400px;
    height: auto;
    object-fit: fill;
}

.containImgMdgLivraison{
    width: 45px;
    height: 45px;
    transform: translateY(5px);
    transition: transform 0.5s;
}

.containImgMdgLivraison:hover{
    transform: translateY(10px);
}

@keyframes truckMoving {
    
    0%{
        opacity: 1;
    }

    80%{
        opacity: 1;
        transform: translateX(300px);
    }
    85%{
        opacity: 1;
        transform: translateX(300px);
    }
    90%{
        opacity: 1;
        transform: translateX(300px);
    }
    95%{
        opacity: 1;
        transform: translateX(300px);
    }
    100%{
        opacity: 1;
        transform: translateX(300px);
    }


}

@media all and (max-width: 1024px){

    .img-fluidheaderlivraisonImgContain{
        width: 300px;
        margin-left: 0;
        height: auto;
        object-fit: fill;
    }

    .containImgMdgLivraison{
        width: 45px;
        height: 45px;
        transform: translateX(-380px) translateY(45px);
        transition: transform 0.5s;
    }
    
    .containImgMdgLivraison:hover{
        transform: translateX(-380px) translateY(45px);
    }

}

@media all and (max-width: 768px){

    .img-fluidheaderlivraisonImgContain{
        width: 200px;
        height: auto;
        object-fit: fill;
    }

    .img-fluidTruckContain{
        width: 100px;
        height: auto;
    }

    .containImgMdgLivraison{
        width: 45px;
        height: 45px;
        transform: translateX(-135px) translateY(40px);
        transition: transform 0.5s;
    }
    
    .containImgMdgLivraison:hover{
        transform: translateX(-135px) translateY(40px);
    }

    @keyframes truckMoving {
    
        0%{
            opacity: 1;
        }
    
        80%{
            opacity: 1;
            transform: translateX(250px);
        }
        85%{
            opacity: 1;
            transform: translateX(250px);
        }
        90%{
            opacity: 1;
            transform: translateX(250px);
        }
        95%{
            opacity: 1;
            transform: translateX(250px);
        }
        100%{
            opacity: 1;
            transform: translateX(250px);
        }
    
    
    }

}

@media all and (max-width: 576px){

    .containImgMdgLivraison{
        width: 45px;
        height: 45px;
        transform: translateX(-220px) translateY(30px);
        transition: transform 0.5s;
    }
    
    .containImgMdgLivraison:hover{
        transform: translateX(-220px) translateY(30px);
    }

}

@media all and (max-width: 420px){

    .containImgMdgLivraison{
        width: 45px;
        height: 45px;
        transform: translateX(-100px) translateY(28px);
        transition: transform 0.5s;
    }
    
    .containImgMdgLivraison:hover{
        transform: translateX(-100px) translateY(28px);
    }

}

@media all and (max-width: 320px){

    .containImgMdgLivraison{
        width: 45px;
        height: 45px;
        transform: translateX(-155px) translateY(58px);
        transition: transform 0.5s;
    }
    
    .containImgMdgLivraison:hover{
        transform: translateX(-155px) translateY(58px);
    }

}