.SectionTemoignage{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0;
}

.imgCircle{
    width: 130px;
    height: 140px;
    z-index: 1;
}

.imgCircleRefrigepeche{
    width: 200px;
    height: 140px;
    z-index: 1;
    padding-bottom: 20px;
}

.img-fluidCircle{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50px;
    margin-top: 50px;
    margin-bottom: 0 auto;
}

.cardTemoin{
    width: 300px;
    height: 350px;
    background-color: rgb(54,54,54);
    border-radius: 20px;
    z-index: 0;
}

.moveMaterauto{
    transform: translateY(-50px);
}

.moveRefrigepeche{
    transform: translateY(80px);
}

.moveCentell{
    transform: translateY(60px);
}

/*ANIMATION POUR LES LOGOS COMMENTS*/
/*SAC 1*/
.bgcomment1{
    position: absolute;
    width: 60px;
    transform: translateX(-50px) translateY(-600px) rotate(60deg);
    z-index: 1;
    animation: moveIconBag 5s infinite;
}

/*SAC 2*/
.bgcomment2{
    position: absolute;
    width: 60px;
    transform: translateX(1250px) translateY(-450px) rotate(-60deg);
    z-index: 1;
    animation: moveIconBag2 5s infinite alternate;
}

/*SAC 3*/
.bgcomment3{
    position: absolute;
    width: 60px;
    transform: translateX(-50px) translateY(-200px) rotate(-95deg);
    z-index: 1;
    transition: transform 1s;
}

.bgcomment3:hover{
    transform: translateX(-50px) translateY(0px) rotate(-275deg);
}


@media all and (max-width: 576px){

    .listTemoin{
        margin-left: 12px;
        margin-right: 0 auto;
    }
    
}

@media all and (max-width: 420px){

    .listTemoin{
        margin-left: 12px;
        margin-right: 0 auto;
    }
    
}