.PolitiqueConfi{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 0 auto;
    border-radius: 20px 20px 20px 20px;
}

.poliTitle {
    color: #0047AB;
}