.SectionReference{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0;
    z-index: 0;
}

.img-fluidReferencePerso{
    width: 150px;
    height: auto;
    object-fit: contain;
}

.mlforReference{
    margin-left: 165px;
    margin-right: 0 auto;
}

.ContainImg-fluidRef{
    width: 150px;
}

.img-fluidRef{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.scaleThis1-5{
    transform: scale(1.5);
}

.scaleThis2{
    transform: scale(2.0);
}

@media all and (max-width: 768px){
    .mlforReference{
        margin-left: 100px;
        margin-right: 0 auto;
    }
}

@media all and (max-width: 420px){
    .mlforReference{
        margin-left: 80px;
        margin-right: 0 auto;
    }
}

@media all and (max-width: 320px){
    .mlforReference{
        margin-left: 50px;
        margin-right: 0 auto;
    }
}