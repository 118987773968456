.SectionTarif{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius:20px 20px 0 0;
    z-index: 0;
}

.cardTarif{
    width: 100%;
    height: 430px;
    background-color: white;
    border-radius: 20px;
    filter: drop-shadow(0 0 0 #83f52c);
    transition: filter 0.5s;
}


.cardTarif:hover{
    filter: drop-shadow(4px 4px 4px #83f52c);
}

.cardTarifHover{
    width: 270px;
    height: 370px;
    background-color: rgba(255, 255, 255, 0.879);
    filter: drop-shadow(0 0 0 #83f52c);
    border-radius: 20px;
    transform: translateY(200px);
    transition: transform 1s;

}

.cardTarif:hover .cardTarifHover{
    transform: translateY(-300px);
}


.tarifcolor{
    color: #50C878;
}

.img-fluidLogoTarif{
    width: 200px;
    margin-left: 30px;
    margin-right: 0 auto;
}

.swiper-custom2 .swiper-button-prev, 
.swiper-custom2 .swiper-button-next {
  color: #83f52c;
}

.swiper-custom2 .swiper-pagination-bullet {
    background-color: rgba(80, 200, 120, 0.264);/* couleur de fond des bullets */
    opacity: 1; /* opacity des bullets */
}
  
.swiper-custom2 .swiper-pagination-bullet-active {
    background-color: #83f52c; /* couleur de fond du bullet actif */
    opacity: 1; /* opacity du bullet actif */
}

.livraisonbtn{
    border: none;
    outline: none;
    background-color: #50C878;
    margin-left: 68px;
    margin-right: 0 auto;
    width: 200px;
    padding: 15px;
}

.livraisonbtn:hover{
    background-color: #50ef85;
}

.mlforzone{
    margin-left: 20px;
    margin-right: 0 auto;
}

.mlfortarif{
    margin-left: 10px;
    margin-right: 0 auto;
}

@media all and (max-width: 1024px){
    .img-fluidLogoTarif{
        width: 250px;
        margin-left: 50px;
        margin-right: 0 auto;
    }
}

@media all and (max-width: 768px){
    .img-fluidLogoTarif{
        width: 250px;
        margin-left: 20px;
        margin-right: 0 auto;
    }
}

@media all and (max-width: 576px){
    .img-fluidLogoTarif{
        width: 250px;
        margin-left: 40px;
        margin-right: 0 auto;
    }
}

@media all and (max-width: 420px){
    .img-fluidLogoTarif{
        width: 250px;
        margin-left: 20px;
        margin-right: 0 auto;
    }
}

@media all and (max-width: 420px){
    .img-fluidLogoTarif{
        width: 250px;
        margin: 0 auto;
    }

    .cardTarifHover{
        width: 240px;
        height: 450px;
    }

    .cardTarif:hover .cardTarifHover{
        transform: translateY(-350px);
    }
    
}