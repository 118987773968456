.SmallDeviceSearch{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #363636;
}

.ptn-searchbar{
    margin-top: -10px;
    margin-bottom: 0 auto;
}