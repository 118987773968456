.SectionAccessoire{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0 0 0 0;
    z-index: 0;
}



/*ICONE BLEU
.iconText3{
    margin-left: 3px;
    margin-right: 0 auto;
    transform: translateY(-10px);
    transition: transform 0.5s;
}

.iconText3:hover{
    transform: translateY(0);
}*/
.bgcasquetaille{
    width: 130px;
}