.SectionPretPorter{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0 0 20px 20px;
    z-index: 0;
}

.bgsafe1porter{
    position: absolute;
    width: 100px;
    transform: translateX(-80px) translateY(-700px) rotate(60deg);
    z-index: 1;
    animation: turnIconSafe 5s infinite alternate;
}
