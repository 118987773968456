.SectionSupportClient{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0 0 20px 20px;
    z-index: 0;
}

.imgSupportClient{
    width: 450px;
    height: auto;
    margin-right: 50px;
}

.imgSupportClientContent{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 20px;
}

.textSupportClient{
    margin-left: 50px;
}

/*ANIMATION POUR LES LOGOS SUPPORT*/
/*SAC 1*/
.bgsupport1{
    position: absolute;
    width: 70px;
    transform: translateX(-50px) translateY(-200px) rotate(60deg);
    z-index: 1;
    animation: turnIconSupport 5s infinite alternate;
}


/*SAC 2*/
.bgsupport2{
    position: absolute;
    width: 70px;
    transform: translateX(1250px) translateY(-350px) rotate(-60deg);
    z-index: 1;
    animation: turnIconSupport2 5s infinite alternate;
}

/*SAC 4*/
.bgsupport4{
    position: absolute;
    width: 70px;
    transform: translateX(420px) translateY(-80px) rotate(30deg);
    z-index: 1;
    transition: transform 1s;
}

.bgsupport4:hover{
    transform: translateX(420px) translateY(-80px) rotate(360deg);
}

@keyframes turnIconSupport {
    0%{
        transform: translateX(-50px) translateY(-200px) rotate(60deg);
    }

    100%{
        transform: translateX(-50px) translateY(-200px) rotate(360deg);
    }
}

@keyframes turnIconSupport2 {
    0%{
        transform: translateX(1250px) translateY(-350px) rotate(-60deg);
    }

    100%{
        transform: translateX(1250px) translateY(-250px) rotate(-360deg);
    }
}

@media all and (max-width: 768px){

    .imgSupportClient{
        width: 300px;
        height: auto;
        margin-right: 0px;
    }

    .imgSupportClientContent{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }

}

@media all and (max-width: 576px){

    .imgSupportClient{
        width: 300px;
        height: auto;
        margin-right: 20px;
        margin-left: 0 auto;
    }

    .contentSupportClient{
        margin-left: -25px;
        margin-right: 0 auto;
    }

}

@media all and (max-width: 420px){

    .imgSupportClient{
        width: 250px;
        height: auto;
        margin-right: 20px;
        margin-left: 0 auto;
    }

    .contentSupportClient{
        margin-left: -25px;
        margin-right: 0 auto;
    }

}

@media all and (max-width: 320px){

    .imgSupportClient{
        width: 200px;
        height: auto;
        margin-right: 20px;
        margin-left: 0 auto;
    }

    .contentSupportClient{
        margin-left: -25px;
        margin-right: 0 auto;
    }

}