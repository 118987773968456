.CatalogueSecurite{
    position: relative;
    width: 100%;
    height: auto;
}

.img-fluidCatalogue{
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0 0 20px 20px;
}

.textDisplay{
    position: relative;
    transform: translateY(-700px);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.244);
}

@media all and (max-width: 1024px){
    
    .textDisplay{
        position: relative;
        transform: translateY(-460px);
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.244);
        font-size: 38px;
    }
    
}

@media all and (max-width: 768px){
    
    .textDisplay{
        position: relative;
        transform: translateY(-350px);
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.244);
        font-size: 38px;
    }
    
}


@media all and (max-width: 576px){
    
    .textDisplay{
        position: relative;
        transform: translateY(-200px);
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.244);
        font-size: 28px;
    }
    
}

