.SectionVetSecurite{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
    z-index: 1;

}

.iconText{
    margin-left: 3px;
    margin-right: 0 auto;
    transform: translateY(-10px);
    transition: transform 0.5s;
}

.iconText:hover{
    transform: translateY(0);
}

/*PARAMETRE IMAGE*/
.wforvetement{
    width: 100%;
    height: auto;
}

.img-fluidforvetement{
    width: 400px;
    height: 50vh;
    object-fit: cover;
    border-radius: 20px;
}

/*superposition images*/
.mfordoubleimages{
    margin-left: 140px;
    margin-right: 0 auto;
}


.transformvetement2{
    transform: translateX(90px) translateY(-90px);
}

/*ANIMATION POUR LES LOGOS SAFE*/
/*SAC 1*/
.bgsafe1{
    position: absolute;
    width: 85px;
    transform: translateX(-80px) translateY(-700px) rotate(60deg);
    z-index: 1;
    animation: turnIconSafe 5s infinite alternate;
}



/*SAC 2*/
.bgsafe2{
    position: absolute;
    width: 80px;
    transform: translateX(1180px) translateY(-550px) rotate(-60deg);
    z-index: 1;
    transition: transform 1s;
}

.bgsafe2:hover{
    transform: translateX(1180px) translateY(-550px) rotate(-360deg);
}

/*SAC 4*/
.bgsafe3{
    position: absolute;
    width: 95px;
    transform: translateX(420px) translateY(-300px) rotate(30deg);
    z-index: 1;
    animation: turnIconSafe3 5s infinite alternate;
}


/*SAC 4*/
.bgsafe4{
    position: absolute;
    width: 95px;
    transform: translateX(50px) translateY(-200px) rotate(-30deg);
    z-index: 1;
    transition: transform 1s;
}

.bgsafe4:hover{
    transform: translateX(50px) translateY(-200px) rotate(360deg);
}

@keyframes turnIconSafe {
    0%{
        transform: translateX(-90px) translateY(-700px) rotate(60deg);
    }

    100%{
        transform: translateX(-90px) translateY(-700px) rotate(360deg);
    }
}

@keyframes turnIconSafe3 {
    0%{
        transform: translateX(420px) translateY(-300px) rotate(30deg);
    }

    100%{
        transform: translateX(420px) translateY(-300px) rotate(360deg);
    }
}

@media all and (max-width: 1024px){
    .img-fluidforvetement{
        width: 250px;
        height: 300px;
        object-fit: cover;
        border-radius: 20px;
    }
}

@media all and (max-width: 768px){

    /*PARAMETRE IMAGE*/
    .wforvetement{
        width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    .img-fluidforvetement{
        width: 250px;
        height: 300px;
        object-fit: cover;
        border-radius: 20px;
    }

    /*superposition images*/
    .mfordoubleimages{
        margin-left: 80px;
        margin-right: 0 auto;
    }
    
    /*Icone
    .iconText{
        margin-left: 3px;
        margin-right: 0 auto;
        transform: translateX(-170px) translateY(30px);
        transition: transform 0.5s;
    }

    .iconText:hover{
        transform: translateX(-170px) translateY(30px);
    }*/

}

@media all and (max-width: 576px){
    

    .img-fluidforvetement{
        width: 300px;
        height: 40vh;
        object-fit: cover;
        border-radius: 20px;
    }

    /*superposition images*/
    .mfordoubleimages{
        margin-top: 50px;
        margin-bottom: 0 auto;
        margin-left: 0;
        margin-right: 0 auto;
    }
    
    /*Icone
    .iconText{
        margin-left: 3px;
        margin-right: 0 auto;
        transform: translateX(0px) translateY(-5px);
        transition: transform 0.5s;
    }

    .iconText:hover{
        transform: translateX(0px) translateY(-5px);
    }*/
}

@media all and (max-width:420px){
   
    .img-fluidforvetement{
        width: 250px;
        height: 40vh;
        object-fit: cover;
        border-radius: 20px;
    }

}

@media all and (max-width: 320px){
   
    .img-fluidforvetement{
        width: 200px;
        height: 40vh;
        object-fit: cover;
        border-radius: 20px;
    }

}