.SectionPretaPorter{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 20px 20px;
    z-index: 0;
}

/*CARD HOVER*/
.swipercardPorter{
    position: relative;
    width: 400px;
    height: auto;
}

.swipercardVar4P{
    position: absolute;
    width: auto;
    height: auto;
    background-color: white;
    transform: translateX(0) translateY(0);
    border-radius: 20px;
    opacity: 0;
    transition: opacity 1s, transform 0.8s;
}

.swipercardPorter:hover .swipercardVar4P{
    transform: translateX(0) translateY(-340px);
    opacity: 1;
}

.img-fluidCard{
    width: 300px;
    height: 50vh;
    object-fit: contain;
}

.img-fluidCard2{
    margin-top: 50px;
    margin-bottom: 0 auto;
    width: 150px;
    height: auto;
    object-fit: contain;
}

.img-fluidRescale2{
    transform: scale(0.8) translateY(50px);
}