.SectionCodeCouleur{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
    z-index: 1;

}

.iconTextCC{
    margin-left: 3px;
    margin-right: 0 auto;
    transform: translateX(-30px) translateY(-10px) rotate(0deg);
    transition: transform 0.5s;
}

.iconTextCC:hover{
    transform: translateX(-30px) translateY(-10px) rotate(360deg);
}

/*ANIMATION POUR LES LOGOS SAFE*/
/*SAC 1*/

.bgpaint2{
    position: absolute;
    width: 65px;
    transform: translateX(200px) translateY(-100px) rotate(-60deg);
    z-index: 1;
    animation: turnIconPaint2 5s infinite alternate;
}

@keyframes turnIconPaint2 {
    0%{
        transform: translateX(200px) translateY(-100px) rotate(-60deg);
    }

    100%{
        transform: translateX(200px) translateY(0) rotate(-360deg);
    }
}

/*Bouton controleur*/

.btnparam{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
}

.btnbgcolor1{
    background-color: #017374;
}

.btnbgcolor1:hover{
    background-color: #017274e0;
}

.btnbgcolor2{

    background-color: #CC5500;

}

.btnbgcolor2:hover{
    background-color: #cc5500da;
}

.btnbgcolor3{

    background-color: #FF00FF;

}

.btnbgcolor3:hover{
    background-color: #ff00ffd2;
}

.btnbgcolor4{
    background-color: #50C878;
}

.btnbgcolor4:hover{
    background-color: #50C878;
}

.btnbgcolor5{

    background-color: #0047AB;

}

.btnbgcolor5:hover{
    background-color: #0047abd7;
}

.btnbgcolor6{

    background-color: #FFDB58;

}

.btnbgcolor6:hover{
    background-color: #ffdb58e3;
}

.btnbgcolor7{

    background-color: #9966CC;

}

.btnbgcolor7:hover{
    background-color: #9966cce7;
}

.btnbgcolor8{

    background-color: #40E0D0;

}

.btnbgcolor8:hover{
    background-color: #40e0d0d9;
}

.btnbgcolor9{

    background-color: #FF7F50;
}

.btnbgcolor9:hover{
    background-color: #ff7f50d3;
}

.btnbgcolor10{

    background-color: #83f52c;

}

.btnbgcolor10:hover{
    background-color: #83f52cdc;
}



.water-wave{
    margin-left: 200px;
    margin-right: 0 auto;
    width: 350px;
    height: 350px;
    border-radius: 0 0 50% 50%;
    /*box-shadow: inset 0 0 20px 0 #1cb4eb30,
    0 0 20px 0 #1cb4eb5c
    ;*/
    position: relative;
    overflow: hidden;
}


.water-wave:after,
.water-wave:before{

    content: "";
    position: absolute;
    width: 220%;
    height: 220%;
    top: -50%;
    left: 50%;
    background: #f1f1f1;

}

.water-wave:after{
    border-radius: 45%;
    animation: water-wave 10s linear infinite;
}

.water-wave:before{

    border-radius: 35%;
    background: rgba(255,255,255,.3) ;
    animation: water-wave 10s linear infinite ;
}

@keyframes water-wave {
    
    0%{
        transform: translate(-50%, -50%) rotate(0deg);
        top: -20%;
    }

    15%{
        top: -30%;
    }

    30%{
        top: -40%;
    }

    40%{
        top: -50%;
    }

    50%{
        top: -60%;
    }

    60%{
        top: -50%;
    }

    70%{
        top: -40%;
    }

    85%{
        top: -30%;
    }

    100%{
        transform: translate(-45%, -50%) rotate(360deg);
        top: -20%;
    }

}


@media all and (max-width: 1024px){

    .btnparam{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: none;
        outline: none;
    }

    .water-wave{
        margin-left: 90px;
        margin-right: 0 auto;
        width: 250px;
        height: 250px;
        border-radius: 0 0 50% 50%;
        /*box-shadow: inset 0 0 20px 0 #1cb4eb30,
        0 0 20px 0 #1cb4eb5c
        ;*/
        position: relative;
        overflow: hidden;
    }

    .iconTextCC{
        width: 80px;
        height: 80px;
        margin-left: 3px;
        margin-right: 0 auto;
        transform: translateX(-30px) translateY(-10px) rotate(0deg);
        transition: transform 0.5s;
    }
    
    .iconTextCC:hover{
        transform: translateX(-30px) translateY(-10px) rotate(360deg);
    }
    

}

@media all and (max-width: 768px){

    .water-wave{
        margin-left: 150px;
        margin-right: 0 auto;
        width: 350px;
        height: 350px;
        border-radius: 0 0 50% 50%;
        /*box-shadow: inset 0 0 20px 0 #1cb4eb30,
        0 0 20px 0 #1cb4eb5c
        ;*/
        position: relative;
        overflow: hidden;
    }

    .iconTextCC{
        width: 60px;
        height: 60px;
        margin-left: 3px;
        margin-right: 0 auto;
        transform: translateX(-30px) translateY(-10px) rotate(0deg);
        transition: transform 0.5s;
    }
    
    .iconTextCC:hover{
        transform: translateX(-30px) translateY(-10px) rotate(360deg);
    }

}

@media all and (max-width: 576px){

    .water-wave{
        margin-left: 15px;
        margin-right: 0 auto;
        width: 250px;
        height: 250px;
        border-radius: 0 0 50% 50%;
        /*box-shadow: inset 0 0 20px 0 #1cb4eb30,
        0 0 20px 0 #1cb4eb5c
        ;*/
        position: relative;
        overflow: hidden;
    }

    .btnparam{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: none;
        outline: none;
    }


}