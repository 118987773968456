.Faq{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 0 auto;
    border-radius: 20px 20px 20px 20px;
}

.cardFAQ{
    width: 300px;
    height: 300px;
    background-color: rgb(54,54,54);
    border-radius: 20px;
    z-index: 0;
}


.swiper-custom3 .swiper-button-prev, 
.swiper-custom3 .swiper-button-next {
  color: #363636;
}

.swiper-custom3 .swiper-pagination-bullet {
    background-color: #807c7c7b;/* couleur de fond des bullets */
    opacity: 1; /* opacity des bullets */
}
  
.swiper-custom3 .swiper-pagination-bullet-active {
    background-color: #363636; /* couleur de fond du bullet actif */
    opacity: 1; /* opacity du bullet actif */
}
