.HeaderPersonnalisation{
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(168deg, rgb(54,54,54) 60%,  white 60%);
    display: grid;
    border-radius: 0 0 0 0;
}

.positionTitlePerso{
    position: relative;
}

.peintureThrow{
    width: 480px;
    height: auto;
}

.img-fluidPeinture{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.mforpeinture{
    position: absolute;
    top: 80px;
    left: -400px
}

.ThrowPeinture{
    animation: throwPaint 8s infinite;
}

.containImgMdgPerso{
    width: 60px;
    height: 60px;
    transform: translateX(-430px) translateY(40px);
    transition: transform 0.5s;
    z-index: 1;
}

.containImgMdgPerso:hover{
    transform: translateX(-430px) translateY(50px);
}

/*Animation lancé de peinture*/
@keyframes throwPaint {
    
    0% {
        transform: scale(2) translateX(-200px);
    }

    10%{
        transform: scale(1);
    }

    20%{
        transform: scale(1);
    }

    30%{
        transform: scale(1);
    }
    
    40%{
        transform: scale(1);
    }

    50%{
        transform: scale(1);
    }

    60%{
        transform: scale(1);
    }

    70%{
        transform: scale(1);
    }

    80%{
        transform: scale(1);
    }

    90%{
        transform: scale(1);
    }

    100%{
        transform: scale(1);
        opacity: 0;
    }

}


.entetePresImgPerso{
    margin-top: -70px;
    margin-bottom: auto;
    width: 400px;
}

@media all and (max-width: 1024px){
    .mforpeinture{
        position: absolute;
        top: 80px;
        left: -350px
    }

    .containImgMdgPerso{
        width: 60px;
        height: 60px;
        transform: translateX(-160px) translateY(40px);
        transition: transform 0.5s;
        z-index: 1;
    }
    
    .containImgMdgPerso:hover{
        transform: translateX(-160px) translateY(50px);
    }
}

@media all and (max-width: 768px){
    .mforpeinture{
        position: absolute;
        top: 80px;
        left: -200px;
    }

    .containImgMdgPerso{
        width: 50px;
        height: 50px;
        transform: translateX(-400px) translateY(35px);
        transition: transform 0.5s;
        z-index: 1;
    }
    
    .containImgMdgPerso:hover{
        transform: translateX(-400px) translateY(35px);
    }
}

@media all and (max-width: 576px){
    .mforpeinture{
        position: absolute;
        top: 80px;
        left: -150px;
    } 

    .containImgMdgPerso{
        width: 60px;
        height: 60px;
        transform: translateX(-150px) translateY(55px);
        transition: transform 0.5s;
        z-index: 1;
    }
    
    .containImgMdgPerso:hover{
        transform: translateX(-150px) translateY(55px);
    }

}

@media all and (max-width: 420px){

    .containImgMdgPerso{
        width: 70px;
        height: 70px;
        transform: translateX(-110px) translateY(40px);
        transition: transform 0.5s;
        z-index: 1;
    }
    
    .containImgMdgPerso:hover{
        transform: translateX(-110px) translateY(40px);
    }

    .h1for320{
        font-size: 25px;
        z-index: 1;
    }

}

@media all and (max-width: 320px){

    .peintureThrow{
        width: 380px;
        height: auto;
    }

    .mforpeinture{
        position: absolute;
        top: 90px;
        left: -200px
    }

    .h1for320{
        font-size: 25px;
    }

    .containImgMdgPerso{
        width: 60px;
        height: 60px;
        transform: translateX(-90px) translateY(45px);
        transition: transform 0.5s;
        z-index: 1;
    }
    
    .containImgMdgPerso:hover{
        transform: translateX(-90px) translateY(45px);
    }

}