.Headerhome{
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(168deg, rgb(54,54,54) 60%,  white 60%);
    display: grid;
    margin: 0;
    padding: 0;
    border-radius: 0 0 0 0;
}

.btnPerso{
    text-decoration: none;
    background-color: #FF7F50;
    padding: 10px 20px;
    color: white;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
}

.btnPerso:hover{
    background-color: #ff7f50e8;
}

.entete{
    margin-top: 130px;
}

.gridHeight{
    height: auto;
}

.bold400{
    font-weight: 400;
}

.bold500{
    font-weight: 500;
}

.bold600{
    font-weight: 600;
}

.fs18{
    font-size: 18px;
}

.fs16{
    font-size: 18px;
}

.fs14{
    font-size: 14px;
}

.fs12{
    font-size: 12px
}


.entetePresImg{
    margin-top: -70px;
    margin-bottom: auto;
    width: 400px;
}

.enteteNewImg{
    margin-top: -100px;
    margin-bottom: auto;
    width: 560px;
}

.enteteNewImg2{
    margin-top: 0px;
    margin-bottom: auto;
    width: 380px;
}

.imgContain{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.containImgMdg{
    width: 45px;
    height: 45px;
    transform: translateY(-2px);
    transition: transform 0.5s;
}


.containImgMdg:hover{
    transform: translateY(2px);
}

.imgMdg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.gapImgMdg{
    gap: 10px;
}


@media all and (max-width: 1024px){
    .enteteNewImg{
        margin-top: -80px;
        margin-bottom: auto;
        width: 400px;
    }
    
}

/*RESPONSIVE*/
@media all and (max-width: 768px){
    .entetePresImg{
        margin-top: -50px;
        margin-bottom: 0 auto;
        width: 300px;
    }

    .enteteNewImg{
        margin-top: -80px;
        width: 400px;
        margin-right: 60px;
    }

    .enteteNewImg2{
        margin-top: 0px;
        margin-bottom: auto;
        width: 320px;
    }

    .containImgMdg{
        width: 80px;
        height: 80px;
        transform: translateY(10px);
        transition: transform 0.5s;
    }
    
    .containImgMdg:hover{
        transform: translateY(10px);
    }

}

@media all and (max-width: 576px) {

    .entetePresImg{
        margin-top: 0px;
        margin-bottom: 0 auto;
        margin-left: 90px;
        margin-right: 0 auto;
        width: 250px;
    }

    .enteteNewImg2{
        margin-top: 0px;
        margin-bottom: auto;
        width: 300px;
    }

    .containImgMdg{
        width: 50px;
        height: 50px;
        transform: translateX(-200px) translateY(30px);
        transition: transform 0.5s;
    }

    .containImgMdgNew2{
        width: 50px;
        height: 50px;
        transform: translateX(-300px) translateY(30px);
        transition: transform 0.5s;
    }
    
    .containImgMdg:hover{
        transform: translateX(-200px) translateY(30px);
    }   

    .containImgMdgNew2:hover{
        transform: translateX(-300px) translateY(30px);
    }   
    
}

@media all and (max-width: 420px){

    .enteteNewImg{
        width: 400px;
        margin-top: -35px;
        margin-left: -50px;
        margin-bottom: 0 auto;
    }

    .containImgMdg{
        width: 50px;
        height: 50px;
        transform: translateX(-155px) translateY(30px);
        transition: transform 0.5s;
    }

    .containImgMdgNew2{
        width: 50px;
        height: 50px;
        transform: translateX(-180px) translateY(30px);
        transition: transform 0.5s;
    }
    
    .containImgMdg:hover{
        transform: translateX(-155px) translateY(30px);
    } 

    .containImgMdgNew2:hover{
        transform: translateX(-180px) translateY(30px);
    } 


}

@media all and (max-width: 320px){

    .enteteNewImg{
        width: 350px;
        margin-top: -20px;
        margin-left: -50px;
        margin-bottom: 0 auto;
    }

    .containImgMdg{
        width: 50px;
        height: 50px;
        transform: translateX(-130px) translateY(25px);
        transition: transform 0.5s;
    }

    .containImgMdgNew2{
        width: 50px;
        height: 50px;
        transform: translateX(-140px) translateY(25px);
        transition: transform 0.5s;
    }

    .containImgMdgHome{
        width: 50px;
        height: 50px;
        transform: translateX(-50px) translateY(25px);
        transition: transform 0.5s;
    }
    
    .containImgMdg:hover{
        transform: translateX(-130px) translateY(25px);
    } 

    .containImgMdgNew2:hover{
        transform: translateX(-140px) translateY(25px);
    } 

    .containImgMdgHome:hover{
        transform: translateX(-50px) translateY(25px);
    } 
}