.equipeCarousel{

    position: relative;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 20px 20px;
    z-index: -1;

}

.equipeText{

    position: relative;
    text-align: left;
    transform: translateY(200px);
    font-size: 48px;
    font-weight: 400;
    z-index: 0;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.371);

}

.img-s1{
    width: 100%;
    height: 85vh;
    object-fit: cover;
}


@media all and (max-width: 768px){

    .img-s1{
        width: 100%;
        height: 60vh;
        object-fit: cover;
    }

    
    .equipeText{

        font-size: 28px;
        font-weight: 400;
        transform: translateY(150px);

    }

}

@media all and (max-width: 576px){

    .img-s1{
        width: 100%;
        height: 40vh;
        object-fit: cover;
    }
    .equipeText{

        font-size: 18px;
        font-weight: 400;
        transform: translateY(90px);

    }

}