.HeaderProduits{
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(168deg, rgb(54,54,54) 60%,  white 60%);
    display: grid;
    border-radius: 0 0 0 0;
}

.imgFill{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.enteteCeintreImg{
    width: 450px;
    height: 50vh;
    margin-left: 150px;
    margin-right: 0 auto;
}

.containImgMdgProduit{
    width: 80px;
    height: 80px;
    transform: translateX(-120px) translateY(30px);
    transition: transform 0.5s;
}

.containImgMdgProduit:hover{
    transform: translateX(-120px) translateY(40px);
}

@media all and (max-width: 1024px){

    .enteteCeintreImg{
        width: 300px;
        height: 50vh;
        margin-left: 100px;
    }

    .containImgMdgProduit{
        width: 90px;
        height: 90px;
        transform: translateX(-40px) translateY(70px);
        transition: transform 0.5s;
    }
    
    .containImgMdgProduit:hover{
        transform: translateX(-40px) translateY(80px);
    }

}

@media all and (max-width: 768px){

    .containImgMdgProduit{
        width: 55px;
        height: 55px;
        transform: translateX(-300px) translateY(35px);
        transition: transform 0.5s;
    }

    .containImgMdgProduit:hover{
        transform: translateX(-300px) translateY(35px);
    }

}

@media all and (max-width: 576px){

    .containImgMdgProduit{
        width: 70px;
        height: 70px;
        transform: translateX(-200px) translateY(50px);
        transition: transform 0.5s;
    }

    .containImgMdgProduit:hover{
        transform: translateX(-200px) translateY(50px);
    }

}

@media all and (max-width: 420px){
    .containImgMdgProduit{
        width: 70px;
        height: 70px;
        transform: translateX(0px) translateY(50px);
        transition: transform 0.5s;
    }

    .containImgMdgProduit:hover{
        transform: translateX(0px) translateY(50px);
    }
}

@media all and (max-width: 320px){
    .containImgMdgProduit{
        width: 100px;
        height: 100px;
        transform: translateX(-100px) translateY(60px);
        transition: transform 0.5s;
    }

    .containImgMdgProduit:hover{
        transform: translateX(-100px) translateY(60px);
    }
}