.SectionVetTravail{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    z-index: 1;
}

/*.iconText2{
    margin-left: 3px;
    margin-right: 0 auto;
    transform: translateY(-10px);
    transition: transform 0.5s;
}


.iconText2:hover{
    transform: translateY(0);
}*/

