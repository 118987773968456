.SectionMedicale{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
    z-index: 0;
}

.cardTextMargin{
    margin-left: -35px;
    margin-right: 0 auto;
}

/*CARD HOVER*/
.swipercard{
    position: relative;
    width: 100%;
    height: auto;
    z-index: -1;
}

.swipercardVar4{
    position: absolute;
    width: auto;
    height: auto;
    background-color: white;
    transform: translateX(0) translateY(0);
    border-radius: 20px;
    opacity: 0;
    transition: opacity 1s, transform 0.8s;
}

.swipercard:hover .swipercardVar4{
    transform: translateX(0) translateY(-340px);
    opacity: 1;
}

.img-fluidCard{
    width: 300px;
    height: 50vh;
    object-fit: contain;
}

.img-fluidCard2{
    margin-top: 50px;
    margin-bottom: 0 auto;
    width: 150px;
    height: auto;
    object-fit: contain;
}

.img-fluidCardBlouse{
    width: 300px;
    height: 50vh;
    object-fit: cover;
    transform: scale(1.2);
}

.img-fluidCardBlouse2{
    margin-top: 50px;
    margin-bottom: 0 auto;
    width: 150px;
    height: auto;
    object-fit: cover;
}

.swiper-custom .swiper-button-prev, 
.swiper-custom .swiper-button-next {
  color: #0047AB;
}

.swiper-custom .swiper-pagination-bullet {
    background-color: #0047ab65; /* couleur de fond des bullets */
    opacity: 1; /* opacity des bullets */
}
  
.swiper-custom .swiper-pagination-bullet-active {
    background-color: #0047AB; /* couleur de fond du bullet actif */
    opacity: 1; /* opacity du bullet actif */
}

.greenLogo{
    width: 70px;
    height: 70px;
}


.iconTextCm{
    margin-left: 3px;
    margin-right: 0 auto;
    transform: translateY(-10px);
    transition: transform 0.5s;
}

.iconTextCm:hover{
    transform: translateY(0);
}



@media all and (max-width: 1024px){

    .swipercard:hover .swipercardVar4{
        transform: translateX(0) translateY(-280px);
        opacity: 1;
    }

    .cardtext1024{
        font-size: 10px;
    }

}

@media all and (max-width: 768px){
    .swipercardVar4{
        width: 200px;
        height: auto;
        background-color: white;
        transform: translateX(50px) translateY(0);
        border-radius: 20px;
        opacity: 0;
        transition: opacity 1s, transform 0.8s;
    }
    
    .swipercard:hover .swipercardVar4{
        transform: translateX(50px) translateY(-300px);
        opacity: 1;
    }
    
}
