.SectionFlexibilite{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0 0 20px 20px;
    z-index: 0;
}

.mforvestenoir{
    width: 100%;
    height: auto;
}

.img-fluidFlexibilite{
    width: 480px;
    height: auto;
    object-fit: fill;
    border-radius: 20px;
}

.iconTextF{
    transform: translateX(-80px) translateY(-10px) rotate(0deg);
    transition: transform 0.5s;
}

.iconTextF:hover{
    transform: translateX(-80px) translateY(-10px) rotate(360deg);
}


@media all and (max-width: 1024px){

    .img-fluidFlexibilite{
        width: 380px;
        height: auto;
        object-fit: fill;
        border-radius: 20px;
    }

}

@media all and (max-width: 768px){

    .iconTextF{
        width: 200px;
        height: 200px;
        transform: translateX(-55px) translateY(70px) rotate(0deg);
        transition: transform 0.5s;
    }
    
    .iconTextF:hover{
        transform: translateX(-55px) translateY(70px) rotate(360deg);
    }

    .img-fluidFlexibilite{
        width: 250px;
        height: 50vh;
        object-fit: cover;
        border-radius: 20px;
    }


}

@media all and (max-width: 576px){

    .img-fluidFlexibilite{
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 20px;
    }

}