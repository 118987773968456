.SectionCategorie{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0 ;
}


/*Texte et Image*/
.catprod{
    margin-top: 100px;
    margin-bottom: 0 auto;
}

.catprodText{
    padding-top: 100px;
    padding-bottom: 0 auto;
}

.imgInGrid{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mlforlg{
    margin-left: 70px;
    margin-right: 0 auto;
}

/*ANIMATION POUR LES LOGOS SACS*/
/*SAC 1*/
.bgsac1{
    position: absolute;
    width: 100px;
    transform: translateX(-50px) translateY(-600px) rotate(60deg);
    z-index: 1;
    animation: moveIconBag 5s infinite;
}


/*SAC 2*/
.bgsac2{
    position: absolute;
    width: 100px;
    transform: translateX(1250px) translateY(-450px) rotate(-60deg);
    z-index: 1;
    animation: moveIconBag2 5s infinite alternate;
}


/*SAC 3*/
.bgsac3{
    position: absolute;
    width: 100px;
    transform: translateX(-50px) translateY(-200px) rotate(-95deg);
    z-index: 1;
    transition: transform 1s;
}

.bgsac3:hover{
    transform: translateX(-50px) translateY(0px) rotate(-275deg);
}


/*Animation moving icon in the background*/
@keyframes moveIconBag2 {

    0%{
        transform: translateX(1250px) translateY(-450px) rotate(-60deg);
    }

    100%{
        transform: translateX(1250px) translateY(-100px) rotate(-360deg);
    }

}

@keyframes moveIconBag {

    0%{
        transform: translateX(-50px) translateY(-600px) rotate(60deg);
    }

    50%{
        transform: translateX(-50px) translateY(-500px) rotate(180deg);
    }

    90%{
        transform: translateX(-50px) translateY(-600px) rotate(360deg);
    }

    100%{
        transform: translateX(-50px) translateY(-600px) rotate(60deg);
    }
    

}



/*RESPONSIVE*/
@media all and (max-width: 2560px){
    .mlforlg{
        margin-left: 50px;
        margin-right: 0 auto;
    }

    .bgGrid{
        background-color: #CC5500;
        text-decoration: none;
        width: 250px;
        height: 25vh;
        border-radius: 20px;
    }

    .bgSmallGrid{
        background-color: #F6AF7C;
        height: 15vh;
    }

    .bgGrid2{
        background-color: #0047AB;
        text-decoration: none;
        width: 250px;
        height: 25vh;
        border-radius: 20px;
    }

    .bgSmallGrid2{
        background-color: #90BDFC;
        height: 15vh;
    }

    .bgGrid3{
        background-color: #62da05;
        text-decoration: none;
        width: 250px;
        height: 25vh;
        border-radius: 20px;
    }

    .bgSmallGrid3{
        margin-top: 50px;
        margin-bottom: 0 auto;
        background-color: #caffa1a6;
        height: 15vh;
    }

    .bgGrid4{
        background-color: #9966CC;
        text-decoration: none;
        width: 250px;
        height: 25vh;
        border-radius: 20px;
    }
    
    .bgSmallGrid4{
        margin-top: 50px;
        margin-bottom: 0 auto;
        background-color: #b593d7e0;
        height: 15vh;
    }
}

@media all and (max-width: 1920px){
    .bgGrid{
        background-color: #CC5500;
        text-decoration: none;
        width: 250px;
        height: 50vh;
        border-radius: 20px;
    }
    
    .bgSmallGrid{
        background-color: #F6AF7C;
        height: 30vh;
    }
    
    .bgGrid2{
        background-color: #0047AB;
        text-decoration: none;
        width: 250px;
        height: 50vh;
        border-radius: 20px;
    }
    
    .bgSmallGrid2{
        background-color: #90BDFC;
        height: 30vh;
    }
    
    .bgGrid3{
        background-color: #62da05;
        text-decoration: none;
        width: 250px;
        height: 50vh;
        border-radius: 20px;
    }
    
    .bgSmallGrid3{
        margin-top: 50px;
        margin-bottom: 0 auto;
        background-color: #caffa1a6;
        height: 30vh;
    }
    .bgGrid4{
        background-color: #9966CC;
        text-decoration: none;
        width: 250px;
        height: 50vh;
        border-radius: 20px;
    }
    
    .bgSmallGrid4{
        margin-top: 50px;
        margin-bottom: 0 auto;
        background-color: #b593d7e0;
        height: 30vh;
    }
}

@media all and (max-width: 768px){
    .mlforlg{
        margin-left: 150px;
        margin-right: 0 auto;
    }

    .bgGrid{
        background-color: #CC5500;
        text-decoration: none;
        width: 360px;
        height: 60vh;
        border-radius: 20px;
    }

    .bgGrid2{
        background-color: #0047AB;
        text-decoration: none;
        width: 360px;
        height: 60vh;
        border-radius: 20px;
    }

    .bgGrid3{
        background-color: #62da05;
        text-decoration: none;
        width: 360px;
        height: 60vh;
        border-radius: 20px;
    }

    .bgGrid4{
        background-color: #9966CC;
        text-decoration: none;
        width: 360px;
        height: 60vh;
        border-radius: 20px;
    }
}

@media all and (max-width: 576px){
    .mlforlg{
        margin-left: 15px;
        margin-right: 0 auto;
    }

    .bgGrid{
        background-color: #CC5500;
        text-decoration: none;
        width: 300px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid2{
        background-color: #0047AB;
        text-decoration: none;
        width: 300px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid3{
        background-color: #62da05;
        text-decoration: none;
        width: 300px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid4{
        background-color: #9966CC;
        text-decoration: none;
        width: 300px;
        height: 55vh;
        border-radius: 20px;
    }
}

@media all and (max-width: 425px){
    .mlforlg{
        margin-left: 10px;
        margin-right: 0 auto;
    }

    .bgGrid{
        background-color: #CC5500;
        text-decoration: none;
        width: 250px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid2{
        background-color: #0047AB;
        text-decoration: none;
        width: 250px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid3{
        background-color: #62da05;
        text-decoration: none;
        width: 250px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid4{
        background-color: #9966CC;
        text-decoration: none;
        width: 250px;
        height: 55vh;
        border-radius: 20px;
    }
}

@media all and (max-width: 320px){
    .mlforlg{
        margin-left: 10px;
        margin-right: 0 auto;
    }

    .bgGrid{
        background-color: #CC5500;
        text-decoration: none;
        width: 200px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid2{
        background-color: #0047AB;
        text-decoration: none;
        width: 200px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid3{
        background-color: #62da05;
        text-decoration: none;
        width: 200px;
        height: 55vh;
        border-radius: 20px;
    }

    .bgGrid4{
        background-color: #9966CC;
        text-decoration: none;
        width: 200px;
        height: 55vh;
        border-radius: 20px;
    }
}


