.HeaderNous{
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(168deg, rgb(54,54,54) 60%,  white 60%);
    display: grid;
    border-radius: 0 0 0 0;
}

.c-i-h-nous{
    width: 20%;
}

.headerimagenous{
    width: 100%;
    height: auto;
    margin-right: 25px;
    margin-left: 0 auto;
}

.img-fluidheaderimagenous{
    width: 500px;
    height: auto;
    border-radius: 20px;
    object-fit: fill;
}

.containImgMdgNous{
    width: 45px;
    height: 45px;
    transform: translateX(0px) translateY(0px);
    transition: transform 0.5s;
}

.containImgMdgNous:hover{
    transform: translateX(0px) translateY(10px);
}

@media all and (max-width: 1024px){

    .img-fluidheaderimagenous{
        width: 380px;
        height: auto;
        border-radius: 20px;
    }

}

@media all and (max-width: 768px){

    .headerimagenous{
        width: 100%;
        height: auto;
        margin: 0;  
    }

    .img-fluidheaderimagenous{
        width: 190px;
        height: 50vh;
        border-radius: 20px;
        object-fit: cover;
    }

}

@media all and (max-width: 576px){

    .containMobile{
        width: 22%;
    }

    .c-i-h-nous{
        width: 100%;
    }

    .marginForCarouselLogoNous{
        margin-left: -100px;
    }

    .containImgMdgNous{
        width: 40px;
        height: 40px;
        transform: translateX(0px) translateY(0px);
        transition: transform 0.5s;
    }
    
    .containImgMdgNous:hover{
        transform: translateX(0px) translateY(0px);
    }
}

@media all and (max-width: 420px){
    .containImgMdgNous{
        width: 40px;
        height: 40px;
        transform: translateX(-205px) translateY(25px);
        transition: transform 0.5s;
    }
    
    .containImgMdgNous:hover{
        transform: translateX(-205px) translateY(25px);
    }
}

@media all and (max-width: 320px){

    .containImgMdgNous{
        width: 40px;
        height: 40px;
        transform: translateX(-40px) translateY(28px);
        transition: transform 0.5s;
    }
    
    .containImgMdgNous:hover{
        transform: translateX(-40px) translateY(28px);
    }

}