.footerAccueil{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg,#CC5500, #0047AB, #FF7F50,#83f52c);
    background-size: 300% 300%;
    height: auto;
    border-radius: 25px 25px 0 0;
    animation: color 12s ease-in-out infinite;
}

.footerProduits{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg, #2a79e7, #CC5500, #0a67e8);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerCatalogueS{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg, #f1f1f1a1, #0047AB, #363636);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerCatalogueP{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg, #f1f1f1a1,#FFDB58, #50C878, #0047AB, #363636);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerPersonnalisation{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg,#0047AB,#FFDB58, #50C878,#CC5500);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerLivraison{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg,#0047AB, #83f52c,#CC5500,#50C878);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerContact{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg,#363636,#FF00FF,#FFDB58,#CC5500);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerNous{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg,#363636,#FF00FF,#CC5500,#f1f1f191);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerPolitique{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg,#363636,#0047AB,#000,#f1f1f191);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.footerFAQ{
    position: relative;
    width: 100%;
    background: linear-gradient(45deg,#363636,#000,#f1f1f191);
    background-size: 300% 300%;
    min-height: 100px;
    margin-top: 50px;
    border-radius: 25px 25px 0 0;
    margin-bottom: 0 auto;
    animation: color 12s ease-in-out infinite;
}

.img-fluidLogotita{
    width: 80px;
    height: auto;
    margin-left: 30px;
    margin-right: 0 auto;
}

.linkFooter{
    text-decoration: none;
}

.gapForInfo{

    gap: 15px;

}

.marginForEmail{
    transform: translateX(-40px);
}

@keyframes color {
    
    0%{
        background-position: 0 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }

}

@media all and (max-width: 1024px){
    .gapForInfo{

        gap: 0px;
    
    }
    
    .marginForEmail{
        transform: translateX(-20px);
    }

    .marginForNumero{
        transform: translateX(-20px);
    }
}

@media all and (max-width: 576px){
    .flex-column-sm{
        display: flex;
        flex-direction: column;
        margin-left: 20px;
    }
}
