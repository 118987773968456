.persoMenu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: rgba(54, 54, 54, 0);
}

.persoMenu.disapear{
    opacity: 0;
    z-index: -1;
}
.persoMenu.scrolled{
    background-color: rgba(54, 54, 54);
}


.dropdown:hover .dropdown-menu{
    display: block;
}

.navbar-nav{
    font-size: 13px;
}

.dropdown-item{
    font-size: 12px;
}

.persoLogo1{
    width: 50px;
}

.persoLogo2{
    width: 50px;
}

.persoPersoText{
    transform: translateX(-10px);
}

.persoLogoTita{
    width: 90px;
}

.persoPerso{
    margin-right: 30px;
    margin-left: 0 auto;
}

.dropdown-menu{
    background-color: #fff;
    box-shadow: 2px 2px 2px #FF00FF;
    border: none;
}

.btnburger{
    margin-top: -10px;
    margin-bottom: 0 auto;
}

/*Barre de recherche*/
.container-rechercher{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.contaniner-barre{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
}

.form-control2{ 
    width: 300px;
    padding: 10px;
    border: none ;
    border-bottom: 1px solid #fff; 
    outline: none ;  
    font-size: 16px ;
    background-color: transparent;
    border-radius: 0;
}

.form-control2:focus {
    background-color: transparent;
    box-shadow: none; /* Supprime l'ombre par défaut de Bootstrap */
    border-color: #f1f1f1;
}

.form-control2::selection {
    background-color: transparent;
    color: inherit; /* Conserve la couleur du texte lors de la sélection */
}

.logo-recherche{
    width: 18px;
    height: 18px;
}

.form-control2::placeholder{
    font-size: 12px;
    color: #fff;
}

.form-control2:focus{
    font-size: 12px;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0 auto;
}

/*************** RESULTAT **************/
#results {
    position: absolute;
    list-style-type: none; /* Supprimer les puces */
    border-radius: 5px; /* Coins arrondis */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre portée */
    width: 300px;
    margin-top: 10px;
    background-color: #363636; /* Couleur de fond */
    color: #fff;
    font-size: 12px;
}
#results li {
    padding: 10px;
}
#results li:last-child {
    border-bottom: none; /* Pas de bordure pour le dernier élément */
}


#results a:hover {
    background-color: transparent; /* Couleur de survol */
}

.listSearch{
    cursor: pointer;
}