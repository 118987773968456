.SectionInformation{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0 0 20px 20px;
}

.img-fluidFuse{
    width: 100px;
    height: auto;
}

.moveFuse{
    opacity: 0;
    transform: translate(-300px);
    animation: moveFuse 5s infinite;
}


@keyframes moveFuse {
    
    0%{
        opacity: 1;
    }   

    50%{
        transform: translateX(-300px) rotate(180deg);
        opacity: 1;
    }

    70%{
        transform: translateX(-300px) rotate(360deg);
        opacity: 1;
    }

    100%{
        transform: translateX(80px) translateY(-300px) rotate(360deg);
        opacity: 0;
    }

}