.SectionEsthetique{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
    z-index: 0;
}

.img-fluidCardKimonoNoir{
    width: 300px;
    height: 50vh;
    object-fit: contain;
    transform: scale(0.8);
}

.img-fluidCardKimonoNoir2{
    margin-top: 50px;
    margin-bottom: 0 auto;
    width: 150px;
    height: auto;
    object-fit: contain;
}