.SectionSav{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: 100vh;
    border-radius: 0 0 20px 20px;
    z-index: 0;
}

.textColor{

    color: #FF00FF;

}

.iconTextSav{
    margin-left: 3px;
    margin-right: 0 auto;
    transform: translateY(-10px);
    transition: transform 0.5s;
}

.iconTextSav:hover{
    transform: translateY(0);
}

.bgsav1{
    width: 90px;
    transform: translateX(250px) translateY(-50px) rotate(40deg);
    animation: turnIconSav 10s infinite;
}

.bgsav2{
    width: 90px;
    transform: translateX(350px) translateY(-100px) rotate(-40deg);
    animation: turnIconSav2 10s infinite;
}

@keyframes turnIconSav {
    0%{
        transform: translateX(250px) translateY(-50px) rotate(40deg);
    }

    25%{
        transform: translateX(250px) translateY(-150px) rotate(360deg);
    }

    50%{
        transform: translateX(250px) translateY(-50px) rotate(40deg);
    }

    75%{
        transform: translateX(250px) translateY(50px) rotate(360deg);
    }

    100%{
        transform: translateX(250px) translateY(-50px) rotate(40deg);
    }
}

@keyframes turnIconSav2 {
    0%{
        transform: translateX(350px) translateY(-100px) rotate(-40deg);
    }

    25%{
        transform: translateX(350px) translateY(0px) rotate(-360deg);
    }

    50%{
        transform: translateX(350px) translateY(-100px) rotate(-40deg);
    }

    75%{
        transform: translateX(350px) translateY(-200px) rotate(-360deg);
    }

    100%{
        transform: translateX(350px) translateY(-100px) rotate(-40deg);
    }
}

@media all and (max-width: 768px){

    .iconTextSav{
        margin-left: 3px;
        margin-right: 0 auto;
        transform: translateY(-10px);
        transition: transform 0.5s;
    }
    
    .iconTextSav:hover{
        transform: translateY(0);
    }

}