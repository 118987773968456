.SectionHotel{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0 0 0 0;
    z-index: 0;
}

.img-fluidRescale{
    transform: scale(0.7);
}