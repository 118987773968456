.SectionFormulaire{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 0 auto;
    border-radius: 20px 20px 0 0;
}

.btnsend{
    text-decoration: none;
    border: none;
}

.img-fluidMail{
    width: 150px;
    height: auto;
}

.moveMail{
    opacity: 0;
    transform: translateX(40px) translateY(350px);
    animation: moveMail 5s infinite alternate;

}

@keyframes moveMail {
    
    0%{
        opacity: 0;
    }

    30%{
        opacity: 1;
    }

    50%{
        transform: translateX(40px) translateY(50px) rotate(360deg);
    }
    
    100%{

        transform: translateX(40px) translateY(50px) rotate(360deg);
        opacity: 1;
    }
}