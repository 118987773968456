.SectionQHSSE{
    position: relative;
    background-color: #F1F1F1;
    width: 100%;
    height: auto;
    border-radius: 0 0 0 0;
    z-index: 0;
}

.bgnoeud1{
    width: 60px;
    transform: translateY(-350px) rotate(40deg);
    transition: transform 1s;
}

.bgnoeud1:hover{
    transform: translateY(-350px) rotate(360deg);
}

.bgnoeud2{
    width: 60px;
    transform: translateX(350px) translateY(-100px) rotate(-40deg);
    transition: transform 1s;
}

.bgnoeud2:hover{
    transform: translateX(350px) translateY(-100px) rotate(360deg);
}


